<template>
  <div>
    <div class="container">
      <div>
        <span>游戏</span>
        <input type="text" v-model="roomeng">
        <span style="margin-left:20px">开奖号码</span>
        <input type="text" v-model="opencode">
        <button style="margin-left:20px" @click="search">查询</button>
      </div>

    </div>
    <div class="contentcontent">

    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  data() {
    return {
      roomeng: "",
      opencode: "",
    };
  },

  methods: {
    search() {},
  },

  watch: {},
};
</script>
<style  scoped>
.contentcontent {
  width: 1200px;
  height: 600px;
  margin: 0 auto;
  border: 1px solid #000;
}
.container {
  width: 1200px;
  margin: 100px auto 20px auto;
}
</style>